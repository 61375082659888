import React, { Fragment } from 'react';
import { useAppState } from '@nutastic/nutastic-react-app';
import VideoHeader from '../VideoHeader';
import PortfolioThumbnail from '../PortfolioThumbnail';

const PortfolioPage = () => {
    const componentClass = 'portfolio-page';
    const {
        videos,
        portfolioData: {
            videoHeader,
            imageHeader,
            title
        }
    } = useAppState();

    return <Fragment>
        <VideoHeader
            className={`${componentClass}__header`}
            image={imageHeader}
            video={videoHeader}
            title={title}
            useFade={true}
        />
        <div className={`${componentClass}__videos page-section page-section--padded`}>
            <div className="container">
                {
                    (videos || []).filter(v => !!v.field_1).map(v => {
                        return <PortfolioThumbnail
                            key={v.id}
                            image={v.image}
                            gif={v.image_1}
                            title={v.title}
                            useBottomTitle={true}
                            subTitle={v.excerpt}
                            videoId={v.field_1}
                            useTitle={true}
                            useFade={true}
                            useGif={ v.num_2 }
                        />;
                    })
                }
            </div>
        </div>
    </Fragment>;
};

export default PortfolioPage;
