
const AppActions = {
  CONTACT_PAGE_OPENED: 'CONTACT_PAGE_OPENED',
  CONTACT_PAGE_CLOSED: 'CONTACT_PAGE_CLOSED',
  ABOUT_PAGE_OPENED: 'ABOUT_PAGE_OPENED',
  ABOUT_PAGE_CLOSED: 'ABOUT_PAGE_CLOSED',
  VIDEO_STARTED: 'VIDEO_STARTED',
  VIDEO_STOPPED: 'VIDEO_STOPPED',
  PORTFOLIO_MODAL_OPENED: 'PORTFOLIO_MODAL_OPENED',
  PORTFOLIO_MODAL_CLOSED: 'PORTFOLIO_MODAL_CLOSED'
};

const toggleContactPage = (shouldEnable) => {
  return async dispatch => {
    const type = shouldEnable ? AppActions.CONTACT_PAGE_OPENED : AppActions.CONTACT_PAGE_CLOSED;
    dispatch({ type });
  };
};

const toggleAboutPage = (shouldEnable) => {
  return async dispatch => {
    const type = shouldEnable ? AppActions.ABOUT_PAGE_OPENED : AppActions.ABOUT_PAGE_CLOSED;
    dispatch({ type });
  };
};

const togglePortfolioModal = (shouldEnable) => {
  return async dispatch => {
    const type = shouldEnable ? AppActions.PORTFOLIO_MODAL_OPENED : AppActions.PORTFOLIO_MODAL_CLOSED;
    dispatch({ type });
  };
};

const openVideoPlayer = (videoId, videoTitle) => {
  return async dispatch => {
    dispatch({ type: AppActions.VIDEO_STARTED, videoId, videoTitle });
  };
};

const closeVideoPlayer = () => {
  return async dispatch => {
    dispatch({ type: AppActions.VIDEO_STOPPED });
  };
};

export {
  AppActions,
  toggleAboutPage,
  toggleContactPage,
  togglePortfolioModal,
  openVideoPlayer,
  closeVideoPlayer
};
