import { AppActions } from './app-actions';
import lockPage from '../utils/lockPage';

const appReducer = (state, action) => {
  switch (action.type) {
    case AppActions.CONTACT_PAGE_OPENED: {
      lockPage(true);
      return {
        ...state,
        showContactPage: true,
        showAboutPage: false
      };
    }

    case AppActions.CONTACT_PAGE_CLOSED: {
      lockPage(false);
      return {
        ...state,
        showContactPage: false
      };
    }

    case AppActions.ABOUT_PAGE_OPENED: {
      lockPage(true);
      return {
        ...state,
        showContactPage: false,
        showAboutPage: true
      };
    }

    case AppActions.ABOUT_PAGE_CLOSED: {
      lockPage(false);
      return {
        ...state,
        showAboutPage: false
      };
    }

    case AppActions.PORTFOLIO_MODAL_OPENED: {
      lockPage(true);
      return {
        ...state,
        showPortfolioModal: true
      };
    }

    case AppActions.PORTFOLIO_MODAL_CLOSED: {
      lockPage(false);
      return {
        ...state,
        showPortfolioModal: false
      };
    }

    case AppActions.VIDEO_STARTED: {
      lockPage(true);
      return {
        ...state,
        videoId: action.videoId,
        videoTitle: action.videoTitle
      };
    }

    case AppActions.VIDEO_STOPPED: {
      lockPage(false);
      return {
        ...state,
        videoId: null,
        videoTitle: ''
      };
    }

    default:
      return state;
  }
};

export default appReducer;
