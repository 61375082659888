import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContainer } from '@nutastic/nutastic-react-app';
import HomePage from '../pages/HomePage';
import Header from '../Header';
import Footer from '../Footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import PortfolioPage from '../pages/PortfolioPage';
import ContactWindow from '../ContactWindow';
import AboutWindow from '../AboutWindow';
import VideoPlayer from '../VideoPlayer';
import PortfolioModal from '../PortfolioModal';

const App = () => {
    const renderPageRoute = () => {
        return <Switch>
            <Route path='/' exact={true} component={HomePage}/>
            <Route path='/home' component={HomePage}/>
            <Route path='/portfolio' component={PortfolioPage}/>
        </Switch>;
    };

    return <ParallaxProvider>
        <AppContainer>
            <Header />
            { renderPageRoute() }
            <Footer />
            <AboutWindow />
            <ContactWindow />
            <PortfolioModal />
            <VideoPlayer />
        </AppContainer>
    </ParallaxProvider>;
};

export default App;
