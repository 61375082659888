import React from 'react';
import { ToggleMenu } from '@nutastic/nutastic-react-app';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const SliderWindow = ({ children, className, isOpen, onClose, title}) => {
    const componentClass = 'sliderWindow';
    const classes = classnames(componentClass, className);

    return <ToggleMenu animation="slideFromRight" isOpen={ isOpen } onOuterClick={ onClose } className={classes}>
        <div className={`${componentClass}__closeBtn`} onClick={onClose} role="button">
            <i className="ion-md-close-circle-outline"/>
        </div>
        <div className={`${componentClass}__title`}>{ title }</div>
        { children }
    </ToggleMenu>;
};
SliderWindow.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

export default SliderWindow;
