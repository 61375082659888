import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ColorBar = ({ className, useRelativePosition, height }) => {
    const componentClass = 'hyColorBar';
    const classes = classnames(componentClass, {
        [`${componentClass}--relativePosition`]: useRelativePosition,
        [`${className}`]: !!className
    });
    const styles = height ? { height } : {};
    return <div className={classes} style={styles}>
        <span /><span /><span />
    </div>;
};
ColorBar.propTypes = {
    className: PropTypes.string,
    useRelativePosition: PropTypes.bool,
    height: PropTypes.number
};

export default ColorBar;
