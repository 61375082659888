
const socialIconClasses = {
    facebook: 'ion-logo-facebook',
    instagram: 'ion-logo-instagram',
    youtube: 'ion-logo-youtube',
    vimeo: 'ion-logo-vimeo',
    twitter: 'ion-logo-twitter',
    linked_in: 'ion-logo-linkedin',
    mail: 'ion-md-mail'
};
const getSocialIcons = type => socialIconClasses[type] || 'ion-logo-rss';

export default getSocialIcons;
