import React from 'react';
import { getBackgroundImg, getPublicImagePath, getUploadedImagePath } from '@nutastic/nutastic-react-app';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PortfolioBox = ({ backgroundImage, color, image, label, message, title, url }) => {
    const componentClass = 'portfolioBox';
    const classes = classnames(componentClass, {
        [`${componentClass}--${color}`]: color
    });

    return <a href={ url || '#!'} className={classes}>
        <div className={`${componentClass}__image`} style={getBackgroundImg(getUploadedImagePath(backgroundImage))}>
            <div className="overlay"/>
            <span>{ label || title }</span>
            <img src={getPublicImagePath('videoThumbnail.png')} style={getBackgroundImg(getUploadedImagePath(image))} alt={title}/>
        </div>
        <h2>{ title }</h2>
        <p>{ message }</p>
    </a>;
};
PortfolioBox.propTypes = {
    backgroundImage: PropTypes.string.isRequired,
    color: PropTypes.string,
    image: PropTypes.string.isRequired,
    label: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string.isRequired,
    url: PropTypes.string
};

export default PortfolioBox;
