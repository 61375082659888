import React from 'react';
import { getBackgroundImg, getUploadedImagePath, useAppDispatch } from '@nutastic/nutastic-react-app';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.cjs';
import SwiperCore, { Autoplay, EffectFade, Navigation } from 'swiper';
import PropTypes from 'prop-types';
import { openVideoPlayer } from '../state/app-actions.mjs';

SwiperCore.use([Navigation, Autoplay, EffectFade]);

const swiperProps = {
    autoplay: { delay: 7000 },
    loop: true,
    slidesPerView: 1,
    navigation: {
        nextEl: '.testimony-nav__next',
        prevEl: '.testimony-nav__prev'
    }
};

const TestimonySlides = ({ slides }) => {
    const dispatch = useAppDispatch();

    const onThumbnailClick = (videoUrl, videoTitle) => {
        if (videoUrl) {
            dispatch(openVideoPlayer(videoUrl, videoTitle));
        }
    };

    return <Swiper className="testimony-swiper" { ...swiperProps }>
        { slides.map((slide, i) => {
            const {
                title,
                excerpt,
                content,
                image: logo,
                field_15: moreInfo,
                field_2: video1Url,
                field_3: video1Title,
                field_4: video2Url,
                field_5: video2Title,
                field_6: video3Url,
                field_7: video3Title,
                image_1: avi,
                image_6: bigImage,
                image_7: smallImage1,
                image_8: smallImage2
            } = slide;
            return <SwiperSlide key={i}>
                <div className="testy">
                    <div className="testy__details">
                        <img src={ getUploadedImagePath(logo) } alt={`${title} | Hello Young Testimony`} />
                        <div className="paragraph">{ content }</div>
                        <div className="testy__user">
                            <div className="avi" style={ getBackgroundImg(getUploadedImagePath(avi)) } />
                            <div className="profile">
                                <h4>{ title }</h4>
                                <span className="color-red">{ excerpt }</span>
                                <span>{ moreInfo }</span>
                            </div>
                        </div>
                    </div>
                    <div className="testy__images">
                        <div className={`bigImg ${video1Url ? 'usePointerCursor' : ''}`} onClick={() => onThumbnailClick(video1Url, video1Title)} style={ getBackgroundImg(getUploadedImagePath(bigImage)) }>
                            { video1Url && <i className="ion-ios-play-circle" /> }
                        </div>
                        <div className="smallImgs">
                            <div className={`smallImg ${video2Url ? 'usePointerCursor' : ''}`} onClick={() => onThumbnailClick(video2Url, video2Title)} style={ getBackgroundImg(getUploadedImagePath(smallImage1)) }>
                                { video2Url && <i className="ion-ios-play-circle" /> }
                            </div>
                            <div className={`smallImg ${video3Url ? 'usePointerCursor' : ''}`} onClick={() => onThumbnailClick(video3Url, video3Title)} style={ getBackgroundImg(getUploadedImagePath(smallImage2)) }>
                                { video3Url && <i className="ion-ios-play-circle" /> }
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>;
        })}
    </Swiper>;
};
TestimonySlides.propTypes = {
    slides: PropTypes.string.isRequired
};

export default TestimonySlides;
