import React from 'react';
import ColorBar from './ColorBar';
import SocialMediaIcons from './SocialMediaIcons';

const Footer = () => {
    const componentClass = 'footer';
    const copyrightYear = new Date().getFullYear(); // TODO: expose copyright year in react-app

    return <footer className={componentClass} id="footer-section">
        <ColorBar />
        <div className="container">
            <span className="copyright">Copyright &copy; {copyrightYear}. | HelloYoung Films | All Rights Reserved</span>
            <div className={`${componentClass}__social`}>
                <SocialMediaIcons />
            </div>
        </div>
    </footer>;
};

export default Footer;
