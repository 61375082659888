import React from 'react';
import PortfolioBox from './PortfolioBox';
import { useAppState } from '@nutastic/nutastic-react-app';

const PortfolioCategories = () => {
    const {
        portfolioInfo: {
            field_1: storyTitle,
            field_2: storyMessage,
            image_2: storyImage,
            image_3: storyGif,
            field_3: marketingTitle,
            field_4: marketingMessage,
            image_5: marketingImage,
            image_6: marketingGif,
            field_5: musicTitle,
            field_6: musicMessage,
            image_8: musicImage,
            image_9: musicGif
        }
    } = useAppState();
    const urlPath = '/portfolio';

    return <React.Fragment>
        <PortfolioBox
            title={ storyTitle }
            image={ storyGif }
            backgroundImage={ storyImage }
            message={ storyMessage }
            url={`${urlPath}/story`}
            color="green"
        />
        <PortfolioBox
            title={ marketingTitle }
            image={ marketingGif }
            backgroundImage={ marketingImage }
            message={ marketingMessage }
            url={`${urlPath}/marketing`}
            color="red"
        />
        <PortfolioBox
            title={ musicTitle }
            image={ musicGif }
            backgroundImage={ musicImage }
            message={ musicMessage }
            url={`${urlPath}/music`}
            color="yellow"
        />
    </React.Fragment>;
};

export default PortfolioCategories;
