import React, { Fragment, useEffect, useState } from 'react';
import { getPublicImagePath, getUploadedImagePath, MarkupRender, useAppDispatch, useAppState } from '@nutastic/nutastic-react-app';
import { Parallax } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import VideoThumbnail from '../VideoThumbnail';
import VideoHeader from '../VideoHeader';
import { toggleAboutPage, toggleContactPage, togglePortfolioModal } from '../../state/app-actions';
import PortfolioCategories from '../PortfolioCategories';
import ColorBar from '../ColorBar';
import TestimonySlides from '../TestimonySlides';

const HomePage = () => {
    const {
        aboutVideo,
        welcomeVideo,
        testimonies,
        homeInfo: {
            content: welcomeText,
            field_1: welcomeTitle,
            field_2: aboutMessage,
            field_3: aboutMoreMessage,
            image_1: headerImage,
            image_3: scrollImage1,
            image_4: scrollImage2,
            image_5: scrollImage3,
            field_10: headerVideo
        }
    } = useAppState();
    const componentClass = 'home-page';
    const dispatch = useAppDispatch();
    const [useParallax, setUseParallax] = useState(false);
    const showContactWindow = () => dispatch(toggleContactPage(true));
    const showAboutWindow = () => dispatch(toggleAboutPage(true));
    const shouldRenderTestimonies = testimonies.length > 0;

    const showPortfolioCategories = () => {
        dispatch(togglePortfolioModal(true));
    };

    useEffect(() => {
        setTimeout(() => setUseParallax(true), 500);
    }, []);

    return <Fragment>
        <VideoHeader image={headerImage} video={headerVideo} />
        <div className={`${componentClass}__aboveTheFold page-section`}>
            <div className="container">
                <div className="aboveTheFold__left">
                    <div>
                        <img src={ getPublicImagePath('emblem.png') } alt="HelloYoung Films Emblem" />
                        <h1>{ welcomeTitle }</h1>
                    </div>
                    <MarkupRender className="paragraph" content={welcomeText} />
                </div>
                <div className="aboveTheFold__right">
                    <VideoThumbnail image={ welcomeVideo.image } gif={ welcomeVideo.image_1 } videoId={ welcomeVideo.field_1 } useGif={ welcomeVideo.num_2 } />
                    <div className="video-title">
                        <h2>{ welcomeVideo.title }</h2>
                        <span>{ welcomeVideo.content }</span>
                    </div>
                </div>
            </div>
        </div>

        <section className={`${componentClass}__portfolio page-section page-section--padded page-section--bordered`}>
            <div className="page-section__title page-section__title--alwaysShow">Portfolio</div>
            <div className="container">
                <Fade bottom delay={200} fraction={0.1}>
                    <div className="portfolioBoxes">
                        <PortfolioCategories />
                    </div>
                </Fade>
            </div>
        </section>

        <section className={`${componentClass}__highlights page-section`}>
            <div className="page-section__title page-section__title--alwaysShow">About Us</div>
            <div className="underlay" />
            <div className="container mt-md-5">
                <MarkupRender content={aboutMessage} className="highlightText" tagName="div" />
                <MarkupRender content={aboutMessage} className="highlightText highlightText--top" tagName="div" />
                <Slide left>
                    <div className="highlightButtons">
                        <button className="btn btn-primary" onClick={showAboutWindow}>Learn More</button>
                        <button onClick={showPortfolioCategories} className="btn btn-outline-primary ml-5">View Portfolio</button>
                    </div>
                </Slide>
                <div className="highlightElements">
                    <Parallax className="highlightElements__images" y={['0px', '-1800px']} tagOuter="figure" disabled={!useParallax}>
                        <img src={getUploadedImagePath(scrollImage1)} alt="Hello Young Films Highlights" />
                        <img src={getUploadedImagePath(scrollImage2)} alt="Hello Young Films Highlights" />
                        <img src={getUploadedImagePath(scrollImage3)} alt="Hello Young Films Highlights" />
                    </Parallax>
                    <Parallax className="highlightElements__bar" x={['0px', '-1200px']} tagOuter="figure" disabled={!useParallax}>
                        <ColorBar />
                    </Parallax>
                </div>
            </div>
        </section>

        { shouldRenderTestimonies && <ColorBar useRelativePosition={true} className="preTest" height={2} /> }
        { shouldRenderTestimonies && <section className={`${componentClass}__testimonies page-section page-section--padded page-section--bordered`}>
            <div className="page-section__title page-section__title--alwaysShow">Testimonials</div>
            <div className="container">
                <TestimonySlides slides={testimonies} />
                <div className="testimony-nav">
                    <i className="ion-ios-arrow-dropleft-circle testimony-nav__prev"/>
                    <i className="ion-ios-arrow-dropright-circle testimony-nav__next"/>
                </div>
            </div>
        </section> }
        { shouldRenderTestimonies && <ColorBar useRelativePosition={true} height={2} /> }

        <section className={`${componentClass}__highlights more-info-section page-section`}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <div className="page-section__title">Let's Work</div>
            <div className="container mt-md-5">
                <Fade delay={500}>
                    <div className="highlight-video">
                        <div className="highlight-video__thumbnail">
                            <VideoThumbnail image={ aboutVideo.image } gif={ aboutVideo.image_1 } videoId={ aboutVideo.field_1 } useGif={ aboutVideo.num_2 } />
                            <div className="video-title">
                                <h2>{ aboutVideo.title }</h2>
                                <span>{ aboutVideo.content }</span>
                            </div>
                        </div>
                    </div>
                </Fade>
                <div className="highlight-title"><span>Let's Work</span></div>
                <MarkupRender content={aboutMoreMessage} className="highlightText highlightText--textRight highlightText--show" tagName="div" />
                <Fade delay={1000} left>
                    <div className="highlightButtons highlightButtons--right">
                        <button onClick={showContactWindow} className="btn btn-secondary">Schedule A Consultation</button>
                        <button onClick={showPortfolioCategories} className="btn btn-outline-secondary ml-5">View Portfolio</button>
                    </div>
                </Fade>
            </div>
        </section>
    </Fragment>;
};

export default HomePage;
