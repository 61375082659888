import { initializeNutasticApp, fetchApiData } from '@nutastic/nutastic-react-app';
import AppLoading from './components/app/AppLoading';
import App from './components/app/App';
import messages from './i18n/messages';
import WebFont from 'webfontloader';
import appReducer from './state/app-reducer';

const init = async () => {
    WebFont.load({
        google: {
            families: [
                'Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900',
                'Syncopate:wght@400;700'
            ]
        }
    });

    await initializeNutasticApp({
        AppComponent: App,
        LoadingComponent: AppLoading,
        loadingDelay: window.location.pathname === '/' ? 1000 : 0,
        initialState: {
            showAboutPage: false,
            showContactPage: false,
            videoId: false
        },
        decorateStateFn: fetchApiData,
        messages,
        reducers: [appReducer]
    });
};

init();
