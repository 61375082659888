import React from 'react';
import Fade from 'react-reveal/Fade';
import { getBackgroundImg, getPublicImagePath, getUploadedImagePath, useAppDispatch } from '@nutastic/nutastic-react-app';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { openVideoPlayer } from '../state/app-actions';

const VideoThumbnail = ({ className, gif, image, label, title, subTitle, useFade, useGif, useTitle, useMobileTitle, videoId }) => {
    const componentClass = 'videoThumbnail';
    const dispatch = useAppDispatch();
    const Component = useFade ? Fade : React.Fragment;
    const shouldRenderGif = gif && parseInt(useGif) !== 0;
    const componentProps = useFade ? { bottom: true, delay: 500, fraction: 0.9 } : {};
    const classes = classnames(componentClass, className, {
        [`${componentClass}--withTitle`]: useTitle,
        [`${componentClass}--withMobileTitle`]: useMobileTitle
    });

    const renderTitleElement = () => <div className={`${componentClass}__title`}>
        <h3>{ title }</h3>
        <span>{ subTitle }</span>
    </div>;

    const onClick = () => {
        dispatch(openVideoPlayer(videoId));
    };

    return <Component {...componentProps}>
        <div className={classes} style={getBackgroundImg(getUploadedImagePath(image))} onClick={onClick}>
            { shouldRenderGif && <div className={`${componentClass}__gif`} style={getBackgroundImg(getUploadedImagePath(gif))} /> }
            <i className="ion-ios-play-circle" />
            <img src={ getPublicImagePath('videoThumbnail.png') } alt={label} />
            { useTitle && renderTitleElement() }
        </div>
    </Component>;
};
VideoThumbnail.propTypes = {
    className: PropTypes.string,
    gif: PropTypes.string,
    image: PropTypes.string.isRequired,
    label: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    useFade: PropTypes.bool,
    useGif: PropTypes.string,
    useTitle: PropTypes.bool,
    videoId: PropTypes.string
};

export default VideoThumbnail;
