import React from 'react';
import { Modal, useAppDispatch, useAppState } from '@nutastic/nutastic-react-app';
import Vimeo from '@u-wave/react-vimeo';
import { closeVideoPlayer } from '../state/app-actions';

const VideoPlayer = () => {
    const componentClass = 'videoPlayer';
    const { videoId, videoTitle } = useAppState();
    const dispatch = useAppDispatch();
    const isActive = !!videoId;

    const onClose = () => {
        dispatch(closeVideoPlayer());
    };

    return <Modal show={isActive} closeBtn={<i className="ion-md-close-circle-outline"/> } onClose={onClose} className={componentClass} nodeId="root">
        { isActive && <div className={`${componentClass}__title`}>{ videoTitle }</div> }
        { isActive && <Vimeo video={videoId} autoplay/> }
    </Modal>;
};

export default VideoPlayer;
