import * as jQuery from 'jquery';

export const $ = jQuery;
export const gridBreakpoints = {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1024,
    xl: 1279,
    xxl: 1440
};
