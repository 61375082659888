import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { getBackgroundImg, getPublicImagePath, getUploadedImagePath, useBreakpoints } from '@nutastic/nutastic-react-app';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Vimeo from '@u-wave/react-vimeo';

let titleTimeout = null;

const VideoHeader = ({ className, image, subtitle, title, useFade, video }) => {
    const componentClass = 'videoHeader';
    const breakpoints = useBreakpoints();
    const TitleComponent = useFade && breakpoints.lg ? Fade : 'div';
    const classes = classnames(componentClass, className);
    const [showTitle, setShowTitle] = useState(true);

    const getVideoImg = () => {
        if (breakpoints.lg) {
            return 'videoHeader-skinny-desktop.png';
        } else {
            return 'videoHeader-skinny.png';
        }
    };

    useEffect(() => {
        titleTimeout = setTimeout(() => setShowTitle(false), 5000);
    }, []);

    const onHover = () => {
        clearTimeout(titleTimeout);
        setShowTitle(true);
    };

    const onHoverExit = () => {
        titleTimeout = setTimeout(() => setShowTitle(false), 1000);
    };

    return <section className={classes} onMouseEnter={onHover} onMouseLeave={onHoverExit}>
        <img src={getPublicImagePath('videoHeader-skinny.png')} alt={title} />
        <span className={`${componentClass}__vimeoWrapper`}>
            <Vimeo
                video={video || 'https://vimeo.com/410509481'}
                autoplay={true}
                showByline={false}
                background={true}
                showTitle={false}
                loop={true}
                className={`${componentClass}__vimeoVideo`}
            />
        </span>
        <TitleComponent when={showTitle}>
            <div>
                { title && <h1>{ title }</h1> }
                { subtitle && <label>{ subtitle }</label>}
            </div>
        </TitleComponent>
    </section>;
};
VideoHeader.propTypes = {
    className: PropTypes.string,
    image: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    useFade: PropTypes.bool,
    video: PropTypes.string.isRequired
};

export default VideoHeader;
