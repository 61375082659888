import React from 'react';
import { Modal, useAppDispatch, useAppState } from '@nutastic/nutastic-react-app';
import PortfolioCategories from './PortfolioCategories';
import { togglePortfolioModal } from '../state/app-actions';

const PortfolioModal = () => {
    const componentClass = 'portfolioModal';
    const { showPortfolioModal } = useAppState();
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(togglePortfolioModal(false));
    };

    return <Modal show={showPortfolioModal} onClose={onClose} closeOnOuterClick={true} className={componentClass} title="Select a Portfolio" closeBtn={<i className="ion-md-close-circle-outline"/>}>
        <div className="container portfolioModal__container">
            <PortfolioCategories />
        </div>
    </Modal>;
};

export default PortfolioModal;
