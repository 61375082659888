import React from 'react';
import { useAppState } from '@nutastic/nutastic-react-app';
import getSocialIcons from '../utils/getSocialIcons';

// TODO: refactor this into react-app
const SocialMediaIcons = () => {
    const { socialMedia } = useAppState();

    return <div>
        { socialMedia.map(s => {
            const hrefProps = s.type === 'mail'
                ? { href: `mailTo:${s.url}`, subject: 'HelloYoungFilms.com Contact' }
                : { href: s.url, target: '_blank' };
            return <a {...hrefProps} key={s.type} rel="noreferrer">
                <i className={`${getSocialIcons(s.type)}`} />
            </a>;
        })}
    </div>;
};

export default SocialMediaIcons;
