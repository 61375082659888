import React from 'react';
import { getPublicImagePath } from '@nutastic/nutastic-react-app';
import { ReactSVG } from 'react-svg';

const AppLoading = () => {
    const componentClass = 'loading-page';
    return <div className={componentClass}>
        <div className={`${componentClass}__content`}>
            <ReactSVG src={getPublicImagePath('loading.svg')} className={`${componentClass}__svgContainer`} />
            <img src={getPublicImagePath('emblem.png')} alt="Hello Young Emblem Logo" />
            <span>Loading...</span>
        </div>
    </div>;
};

export default AppLoading;
