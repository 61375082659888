import React from 'react';
import Fade from 'react-reveal/Fade';
import { getBackgroundImg, getPublicImagePath, getUploadedImagePath, useAppDispatch } from '@nutastic/nutastic-react-app';
import PropTypes from 'prop-types';
import { openVideoPlayer } from '../state/app-actions';

const PortfolioThumbnail = ({ gif, image, label, title, subTitle, useGif, videoId }) => {
    const componentClass = 'portfolioThumbnail';
    const dispatch = useAppDispatch();
    const shouldRenderGif = gif && parseInt(useGif) !== 0;
    const fadeProps = { bottom: true, delay: 200, fraction: 0.9 };

    const onClick = () => {
        dispatch(openVideoPlayer(videoId));
    };

    return <Fade {...fadeProps}>
        <div className={componentClass} onClick={onClick}>
            <div className={`${componentClass}__image`} style={getBackgroundImg(getUploadedImagePath(image))}>
                { shouldRenderGif && <div className={`${componentClass}__gif`} style={getBackgroundImg(getUploadedImagePath(gif))} /> }
                <i className="ion-ios-play-circle" />
                <img src={ getPublicImagePath('videoThumbnail.png') } alt={label} />
                <div className={`${componentClass}__title`}>
                    <h3>{ title }</h3>
                    <span>{ subTitle }</span>
                </div>
            </div>
            <div className={`${componentClass}__footer`}>
                <h3>{ title }</h3>
                <span>{ subTitle }</span>
            </div>
        </div>
    </Fade>;
};
PortfolioThumbnail.propTypes = {
    gif: PropTypes.string,
    image: PropTypes.string.isRequired,
    label: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    useGif: PropTypes.string,
    videoId: PropTypes.string
};

export default PortfolioThumbnail;
