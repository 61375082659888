import React from 'react';
import Fade from 'react-reveal/Fade';
import { getPublicImagePath, getUploadedImagePath, MarkupRender, useAppDispatch, useAppState } from '@nutastic/nutastic-react-app';
import { toggleAboutPage } from '../state/app-actions';
import SliderWindow from './SliderWindow';
import SocialMediaIcons from './SocialMediaIcons';

const AboutWindow = () => {
    const {
        showAboutPage,
        aboutInfo,
        aboutInfoClients
    } = useAppState();
    const dispatch = useAppDispatch();
    const componentClass = 'aboutWindow';
    const onClose = () => {
        dispatch(toggleAboutPage(false));
    };

    return <SliderWindow isOpen={ showAboutPage } onClose={onClose} className={componentClass} title="About">
        <section>
            <h1>{ aboutInfo.field_1 }</h1>
            <MarkupRender content={ aboutInfo.content } tagName="div" className="paragraph" />
        </section>
        <div className={`${componentClass}__social`}><SocialMediaIcons /></div>

        <section>
            <h1>Clients</h1>
            <Fade delay={1000} right when={showAboutPage}>
                <div className={`${componentClass}__clients`}>
                    { aboutInfoClients.map(c => <img src={getUploadedImagePath(c.image)} alt={c.name} key={c.banner_id} />) }
                </div>
            </Fade>
        </section>

    </SliderWindow>;
};

export default AboutWindow;
