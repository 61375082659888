import React, { useState } from 'react';
import classnames from 'classnames';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { getPublicImagePath, useAppDispatch, ToggleMenu } from '@nutastic/nutastic-react-app';
import { toggleContactPage, togglePortfolioModal } from '../state/app-actions';
import PortfolioCategories from './PortfolioCategories';

const mouseHoverTypes = {
    button: 'button',
    drawer: 'drawer'
};

const Header = () => {
    const dispatch = useAppDispatch();
    const componentClass = 'header';
    const scrollThreshold = 650;
    const [hasPastScrollThreshold, setHasPastScrollThreshold] = useState(false);
    const [isHoveringButton, setIsHoveringButton] = useState(false);
    const [isHoveringDrawer, setIsHoveringDrawer] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const showDrawer = isHoveringButton || isHoveringDrawer;
    const classes = classnames(componentClass, {
        [`${componentClass}--isOpen`]: isMenuOpen,
        [`${componentClass}--pastTheFold`]: hasPastScrollThreshold
    });

    useScrollPosition(({ prevPos, currPos }) => {
        const { y = 0 } = currPos;
        if (y < -scrollThreshold) {
            !hasPastScrollThreshold && setHasPastScrollThreshold(true);
        } else {
            hasPastScrollThreshold && setHasPastScrollThreshold(false);
        }
    }, [hasPastScrollThreshold]);

    const showContactForm = () => {
        dispatch(toggleContactPage(true));
        setIsMenuOpen(false);
    };

    const onPortfolioHover = type => {
        if (type === mouseHoverTypes.button) {
            setIsHoveringButton(true);
        } else {
            setIsHoveringDrawer(true);
        }
    };

    const onPortfolioHoverLeave = type => {
        const timeout = 250;
        if (type === mouseHoverTypes.button) {
            setTimeout(() => setIsHoveringButton(false), timeout);
        } else {
            setTimeout(() => setIsHoveringDrawer(false), timeout);
        }
    };

    const portfolioDropdown = <div
        onMouseEnter={() => onPortfolioHover(mouseHoverTypes.drawer)}
        onMouseLeave={() => onPortfolioHoverLeave(mouseHoverTypes.drawer)}
        className={`${componentClass}__portfolioDrawer animated fadeInDown ${showDrawer ? 'active' : ''}`}
    >
        <div className="container">
            <PortfolioCategories />
        </div>
    </div>;

    const mobileMenu = <ToggleMenu
        isOpen={isMenuOpen}
        animation="slideFromRight"
        onOuterClick={() => setIsMenuOpen(false)}
        className="mobileMenu"
    >
        <a href="/">Home</a>
        <span onClick={() => dispatch(togglePortfolioModal(true))}>Portfolio</span>
        <span onClick={showContactForm}>Contact</span>
    </ToggleMenu>;

    return <React.Fragment>
        <header className={ classes }>
            <div className={`${componentClass}__whiteGround`} />
            <div className="container">
                <a href="/" >
                    <img src={ getPublicImagePath('logo.png') } alt="HelloYoung Films Logo" className="logo"/>
                </a>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li onMouseEnter={() => onPortfolioHover(mouseHoverTypes.button)} onMouseLeave={() => onPortfolioHoverLeave(mouseHoverTypes.button)}>
                        <span className={showDrawer ? 'active' : ''} >Portfolio</span>
                    </li>
                    <li onClick={showContactForm}><span>Contact</span></li>
                </ul>
                <div className={`${componentClass}__menu-btn`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <i className={ isMenuOpen ? 'ion-ios-close' : 'ion-ios-menu' } />
                </div>
            </div>
            { portfolioDropdown }
            { mobileMenu }
        </header>
    </React.Fragment>;
};

export default Header;
