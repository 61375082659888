import { $ } from './constants';

const lockPage = (shouldBeLocked) => {
    const $htmlElem = $('html');
    const className = 'locked';
    if (shouldBeLocked) {
        $htmlElem.addClass(className);
    } else {
        $htmlElem.removeClass(className);
    }
};

export default lockPage;
