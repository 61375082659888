import React from 'react';
import { MarkupRender, useAppDispatch, useAppState } from '@nutastic/nutastic-react-app';
import { toggleContactPage } from '../state/app-actions';
import SliderWindow from './SliderWindow';
import SocialMediaIcons from './SocialMediaIcons';

const ContactWindow = () => {
    const { showContactPage, contactInfo } = useAppState();
    const dispatch = useAppDispatch();
    const componentClass = 'contactWindow';
    const onClose = () => {
        dispatch(toggleContactPage(false));
    };

    return <SliderWindow isOpen={ showContactPage } onClose={onClose} className={componentClass} title="Contact">
        <section>
            <MarkupRender content={ contactInfo.content } tagName="div" className="paragraph" />
            <div className={`${componentClass}__social`}><SocialMediaIcons /></div>
            <form className="form-content">
                <div className="form-field">
                    <label>Full Name</label>
                    <input type="text" name="email" className="form-control name" placeholder="Full Name"/>
                </div>
                <div className="form-field">
                    <label>Email</label>
                    <input type="text" name="email" className="form-control email" placeholder="Email"/>
                </div>
                <div className="form-field form-field--full">
                    <label>Location(s) of Production</label>
                    <textarea className="form-control" name="locations" rows="2" />
                </div>
                <div className="form-field">
                    <label>Project Film Date(s)</label>
                    <input type="text" name="film_date" className="form-control"/>
                </div>
                <div className="form-field">
                    <label>Project Due Date</label>
                    <input type="text" name="delivery_date" className="form-control"/>
                </div>
                <div className="form-field form-field--full">
                    <label>Anticipated Budget for Project</label>
                    <input type="text" name="budget" className="form-control"/>
                </div>
                <div className="form-field form-field--full">
                    <label>Message</label>
                    <textarea className="form-control" name="message" rows="2" />
                </div>
                <div className="form-actions">
                    <div>
                        <a href="mailto:hello@helloyoungfilms.com">hello@helloyoungfilms.com</a>
                        <a href="tel:929-274-1996">929-274-1996</a>
                        <a href="https://www.instagram.com/helloyoungfilms/?hl=en" target="_blank" rel="noreferrer">@HelloYoungFilms</a>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>
        </section>
    </SliderWindow>;
};

export default ContactWindow;
